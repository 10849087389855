import React from 'react'
import { message } from 'antd';
import { Outlet, Navigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "redux/store";
import Cookies from 'js-cookie';
import { PAGE_URLS } from "constants/common";
import { fetchRefreshToken } from 'features/common/authAPI';
import { setIsLoggedIn, setJwtToken, setIsAuthenticating } from 'features/common/authSlice';
import PageLoader from 'features/common/pageLoader/PageLoader';

const PrivateRoute = () => {
    const { isLoggedIn, jwtToken, isAuthenticating } = useAppSelector(state => state.auth);

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        const isAccessTokenValid = Cookies.get('access_token_expiry');
        if (!isAccessTokenValid || !jwtToken) {
            getRefreshToken();
        } else {
            dispatch(setIsLoggedIn(true));
            dispatch(setIsAuthenticating(false));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRefreshToken = async () => {
      const res = await fetchRefreshToken();

      if (!!res) {
        dispatch(setJwtToken(res.access));
        dispatch(setIsLoggedIn(true));
      } else {
        message.warning("Session Expired.");
        dispatch(setJwtToken(""));
        dispatch(setIsLoggedIn(false));
      }
      dispatch(setIsAuthenticating(false));
    };

    if (isAuthenticating) {
      return <PageLoader />;
    }

    return isLoggedIn ? <Outlet /> : <Navigate to={PAGE_URLS.LOGIN} />;
}

export default PrivateRoute;
