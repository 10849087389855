import { PAGE_URLS } from 'constants/common';
import HomeHeader from 'features/common/pageHeader/PageHeader';
import AddNewTopup from 'features/topUp/addNewTopup/AddNewTopup';
import TopUpHistory from 'features/topUp/topupHistory/TopUpHistory';
import React from 'react';
import { useLocation } from 'react-router-dom';
import './style.less';

/**
 * @method ResetPassword
 * @description TopUp Page
 * @returns {JSX.Element}
 */

const TopUp: React.FC = (): JSX.Element => {
  const {pathname} = useLocation();

  return (
    <div data-testid="topup-page">
      <HomeHeader
        heading={pathname === PAGE_URLS.TOPUP ? "Top Up History" : "Top Up"}
        refreshMsgRequired={true}
      />
      <div className="app-container topup">
        <div className="top-up">
          {pathname === PAGE_URLS.TOPUP_PAGE && <AddNewTopup />}
          {pathname === PAGE_URLS.TOPUP && <TopUpHistory />}
        </div>
      </div>
    </div>
  );
}

export default TopUp;