import { orderResendDetails, orderResend } from 'features/resend/ReSendAPI';
import { Button, Form, Modal } from 'antd';
import HomeHeader from 'features/common/pageHeader/PageHeader';
import React, { useState } from 'react';
import './style.less';
import { REGEX } from 'constants/common';
import Search from 'antd/lib/input/Search';
import { SearchOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface OrderType {
  order_details_url: string;
}

/**
 * @method Resend
 * @description Reports page
 * @returns {JSX.Element}
 */

const Resend: React.FC = (): JSX.Element => {
  const [isFetching, setFetching] = React.useState<boolean>(false);
  const [data, setData] = useState<OrderType | any>();
  const [inputValue, setInputValue] = useState<number>();
  const [resendSubmit, setResendSubmit] = useState<boolean>(false);
  const [form] = Form.useForm<any>();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e = e || window.event;
    const key = e.which || e.keyCode;

    const command = e.metaKey ? e.metaKey : key === 91 ? true : false;
    const ctrl = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false;

    // allowing only pasted value ,right-left arrows and enter key
    if (
      (key === 86 && ctrl) ||
      (key === 86 && command) ||
      key === 37 ||
      key === 39 ||
      key === 13
    ) {
      return;
    }

    // allowing only number keys
    if (!e.key.match(REGEX.NUMBER_ONLY) && e.keyCode !== 8) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = parseInt(e.clipboardData?.getData('Text'));

    if (isNaN(pastedValue)) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  // #. Getting order id value
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  // #. Order details API call
  const handleResendDetails: any = async () => {
    setFetching(true);
    setData(null);
    const payload = {
      order_id: inputValue,
    };
    const res = await orderResendDetails(payload);
    const fieldError = res.data?.errors?.order_id;
    if (res.ok) {
      setData(res.data?.data);
    } else {
      if (res.data.errors?.order_id) {
        form.setFields([{ name: 'order_id', errors: fieldError }]);
      }
      !fieldError &&
        Modal.error({
          width: 468,
          className: 'modal-dialogs',
          title: 'Resend',
          content: res.data?.message,
          centered: true,
        });
    }
    setFetching(false);
  };

  // #. Order Resend API Call
  const handleResendAPI = async () => {
    const payload = {
      order_id: inputValue,
    };
    const res = await orderResend(payload);
    if (res.ok) {
      Modal.success({
        width: 468,
        className: 'modal-dialogs',
        title: 'Resend',
        content: res.data?.message,
        centered: true,
      });
    } else {
      Modal.error({
        width: 468,
        className: 'modal-dialogs',
        title: 'Resend',
        content: res.data?.message,
        centered: true,
      });
    }
  };

  // #. Order Resend Confirmationm
  const handleResendConfirmation: any = async () => {
    setResendSubmit(true);
    Modal.confirm({
      title: 'Confirm',
      className: 'confirm-modal',
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: 'On confirming the voucher will be resent to the customer. Please confirm',
      onOk: handleResendAPI,
    });
    setResendSubmit(false);
  };

  return (
    <>
      <HomeHeader heading="Resend" refreshMsgRequired={true} />
      <div className="app-container resend" data-testid="resend">
        <div className="resend-container">
          <div className="resend-container-header">
            <div className="resend-container-header-form">
              <h3>Enter your Order ID for resending gift voucher</h3>
              <Form form={form}>
                <Form.Item
                  name="order_id"
                  rules={[
                    { required: true, message: "A valid integer is required." },
                  ]}
                  className="resent-input-form-item"
                >
                  <Search
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    enterButton={
                      isFetching ? (
                        <img src="/images/spinner.svg" alt="spinner" />
                      ) : (
                        <>
                          {" "}
                          <SearchOutlined style={{ color: "#000" }} />
                        </>
                      )
                    }
                    onPaste={handlePaste}
                    onSearch={handleResendDetails}
                  />
                </Form.Item>
              </Form>
            </div>
            <div className="resend-container-header-button">
              <Button
                type="primary"
                loading={resendSubmit}
                disabled={!data?.order_details_url ? true : false}
                onClick={handleResendConfirmation}
              >
                Resend
              </Button>
            </div>
          </div>
          <div className="resend-container-table">
            {data?.order_details_url && (
              <iframe
                className="resend-iframe"
                src={data && data?.order_details_url}
                allowFullScreen
                width="100%"
                height="100%"
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Resend;
