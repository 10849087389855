import React from "react";
import "./style.less";

interface HomeHeaderProps {
  heading?: string;
  refreshMsgRequired?: boolean;
  children?: JSX.Element;
}

/**
 * @method HomeHeader
 * @description Header Component for Dashboard
 * @returns {JSX.Element}
 */

const HomeHeader: React.FC<HomeHeaderProps> = (props): JSX.Element => {
  return (
    <div className="home-header normal-line-height">
      <div className="home-header-heading" data-testid="dashboard-header">
        <div className="home-header-heading-page-name">
          <div className="page-name">{props?.heading}</div>
        </div>
        {props?.children}
      </div>
      {props?.refreshMsgRequired && (
        <div className="content-box">
          <img src={`${process.env.PUBLIC_URL}/images/eye-button.png`} />

          <div>Data is refreshed on hourly basis</div>
        </div>
      )}
    </div>
  );
};

export default HomeHeader;
