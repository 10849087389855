import Cookies from 'js-cookie';
import { message } from 'antd';
import { store } from 'redux/store';
import { API_BASE_URL } from 'constants/apiUrls';
import { setOauthToken, setJwtToken, setIsLoggedIn } from 'features/common/authSlice';
import { fetchOauthToken, fetchRefreshToken } from 'features/common/authAPI';
import { PAGE_URLS } from 'constants/common';

const fetcher: any = async (
    url: RequestInfo,
    options: RequestInit | undefined,
    isJWTRequired = false
) => {
    const res = await fetch(url, options);
    if (res.ok) {
      return promiseResolveHandler(res);
    } else {
      if (res.status === 400 || res.status === 429) {
        return promiseRejectHandler(res);
      } else if (res.status === 401 && !isJWTRequired) {
        await store.dispatch(setOauthToken(""));
        return await fetchAPIwithOauth(url, options);
      } else if (res.status === 401 && isJWTRequired) {
        await store.dispatch(setJwtToken(""));
        return await fetchAPIwithJWT(url, options);
      } else if (res.status === 403) {
        await store.dispatch(setIsLoggedIn(false));
        return await fetchAPIwithJWT(url, options);
      } else {
        // Go to Error Page
        console.log("Error");
        window.location.href = `/error/${res.status}`;
      }
    }
}

const promiseResolveHandler = async (res: {
    json: () => Promise<any>;
    text: () => Promise<any>;
    status: any;
    ok: any;
    headers: any;
}) => {
    return new Promise(async (resolve) => {
        const resContent = res.headers.get('content-type');
        let data = null;

        if (resContent?.includes('application/json')) {
            data = await res.json();
        } else if (resContent?.includes('text/csv')) {
            data = await res.text();
        }

        resolve({
            status: res.status,
            ok: res.ok,
            data,
        });
    }
    );
};

export const promiseRejectHandler = (res: {
    json: () => Promise<any>;
    text: () => Promise<any>;
    status: any;
    ok: any;
}) => {
    return new Promise((reject) =>
        (res.status === 400 || res.status === 429 ? res.json() : res.text()).then(
            (data: any) =>
                reject({
                    status: res.status,
                    ok: res.ok,
                    data,
                })
        )
    );
};

const fetchAPI: any = async (
    endPointUrl: string,
    options: RequestInit | undefined,
    isJWTRequired = false
) => {

    let url = endPointUrl;
    if (!(endPointUrl.indexOf('http://') === 0 || endPointUrl.indexOf('https://') === 0)) {
        // prepending the baseurl if not absolute urls
        url = `${API_BASE_URL}${endPointUrl}`;
    }

    try {
        if (isJWTRequired) {
            // for api requires jwt token
            return await fetchAPIwithJWT(url, options);
        } else {
            // for api requires oath token
            return await fetchAPIwithOauth(url, options);
        }
    } catch (error: any) {
        console.log("API ERROR", error)

        return ({
            ok: false
        })

    }
};



const fetchAPIwithJWT = async (
    url: RequestInfo,
    options: RequestInit | undefined
) => {
    const accessToken = await store.getState().auth.jwtToken;
    const isAccessTokenValid = Cookies.get('access_token_expiry');

    if (!accessToken || !isAccessTokenValid) {
        const res = await fetchRefreshToken();
        if (!res) {
          console.log("Session expired. Please Login.");
          store.dispatch(setIsLoggedIn(false));
          store.dispatch(setJwtToken(""));
          return false;
        }

        const accessToken = await res.access;
        store.dispatch(setJwtToken(accessToken));
        const newOptions = await updateAuthHeader(accessToken, options);
        return await fetcher(url, newOptions, true);

    }
    const newOptions = await updateAuthHeader(accessToken, options);
    return await fetcher(url, newOptions, true);
};

const fetchAPIwithOauth = async (
    url: RequestInfo,
    options: RequestInit | undefined
) => {
    let accessToken = await store.getState().auth.oauthToken;
    if (!accessToken) {
        const res = await fetchOauthToken();
        if (!res) {
            message.error("Something went wrong. Please contact your admin.")
            return;
        }
        accessToken = res.access_token;
        store.dispatch(setOauthToken(accessToken));
    }
    const newOptions = await updateAuthHeader(accessToken, options);
    return await fetcher(url, newOptions);
};


const updateAuthHeader = async (accessToken: string, options: any) => {
    let newHeaderOptions;
    if (options.headers) {
        options.headers.set('Authorization', `Bearer ${accessToken}`);
        newHeaderOptions = {
            headers: options.headers,
        };
    } else {
        const headers = new Headers();
        headers.set('Authorization', `Bearer ${accessToken}`);
        newHeaderOptions = {
            headers: headers,
        };
    }

    return {
        ...options,
        ...newHeaderOptions,
    };
};

export default fetchAPI;