import React from "react";
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { PAGE_URLS } from "constants/common";
import PrivateRoute from "./PrivateRoute";
import ErrorPage from "pages/errorPage/ErrorPage";
import PageLoader from "features/common/pageLoader/PageLoader";
import Orders from "@pages/orders/Orders";
import Resend from "@pages/resend/Resend";
import TopUp from "@pages/topup/TopUp";
import ApiCredentials from "@pages/apiCredentials/ApiCredentials";
import Settings from "@pages/settings/Settings";
import Brands from "@pages/brands/Brands";

const Health = React.lazy(() => import("pages/health/Health"));
const DashboardLayout = React.lazy(() => import("../layout/dashboardLayout"));
const Home = React.lazy(() => import("pages/home/Home"));
const Login = React.lazy(() => import("pages/login/Login"));
const OTPVerification = React.lazy(
  () => import("pages/otpVerification/OtpVerification")
);
const ResetPassword = React.lazy(
  () => import("pages/resetPassword/ResetPassword")
);
const CorporateSelection = React.lazy(
  () => import("@pages/corporateSelection/CorporateSelection")
);

export default function Router() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<PageLoader />}>
        <Routes>
          <Route path={PAGE_URLS.HEALTH} element={<Health />} />

          <Route element={<PrivateRoute />}>
            <Route
              path={PAGE_URLS.CORPORATE_SELCTION}
              element={<CorporateSelection />}
            />
            <Route element={<DashboardLayout />}>
              <Route path={PAGE_URLS.HOME} element={<Home />} />
              <Route path={PAGE_URLS.ORDERS_DEATAILS} element={<Orders />} />

              <Route path={PAGE_URLS.RESEND} element={<Resend />} />

              <Route path={PAGE_URLS.TOPUP} element={<TopUp />} />

              <Route path={PAGE_URLS.BRANDS} element={<Brands />} />

              <Route path={PAGE_URLS.TOPUP_PAGE} element={<TopUp />} />

              <Route
                path={PAGE_URLS.API_CREDENTIALS}
                element={<ApiCredentials />}
              />
              <Route path={PAGE_URLS.CREATE_API} element={<ApiCredentials />} />
              <Route path={PAGE_URLS.SETTINGS} element={<Settings />} />
              <Route
                path={PAGE_URLS.SETTINGS_CHANGE_PASSWORD}
                element={<Settings />}
              />
            </Route>
          </Route>
          <Route path="/" element={<Navigate to={PAGE_URLS.LOGIN} />} />
          <Route path={PAGE_URLS.LOGIN} element={<Login />} />
          <Route
            path={PAGE_URLS.OTP_VERIFICATION}
            element={<OTPVerification />}
          />
          <Route path={PAGE_URLS.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={PAGE_URLS.ERROR_PAGE} element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
}
